<template>
  <div id="c1-gigya-container"></div>
</template>

<script setup>
const config = useRuntimeConfig();
const { activateTheme } = useThemeService();
const { hideLoader } = useLoaderService();
const { getInvitePreFill, getInviteDetails } = useInvitationService();

const themes = {
  '-primary': config.public.themeTypes.primary.code,
  '-secondary': config.public.themeTypes.secondary.code
};

const route = useRoute();

definePageMeta({
  layout: 'user-reg-login-layout',
  middleware: ['common-loader', 'page-redirection']
});

onBeforeMount(() => {
  activateTheme(
    themes[route.params.segment] ?? config.public.themeTypes.adult.code
  );
  hideLoader();
});

onMounted(() => {
  if (getInvitePreFill()) {
    window.addEventListener('GIGYA_AFTER_SCREEN_LOAD', handleAfterScreenLoad);
  }
  if (window['gigya']) {
    initGigyaScreen(
      '',
      config.public.gigyaScreens[route.path],
      'c1-gigya-container'
    );
  } else {
    window.addEventListener('gigyaLoaded', () =>
      initGigyaScreen(
        '',
        config.public.gigyaScreens[route.path],
        'c1-gigya-container'
      )
    );
  }
});

function handleAfterScreenLoad(data) {
  const inviteDetails = getInviteDetails();
  if (data.detail.event.currentScreen == 'nemo-login') {
    //Set data and disable fields and links to move to other screen
    setInviteData(inviteDetails);
    disableFieldsAndLinks();
    setInvitationTooltipVisibility(true);
  }
}

function setInviteData(inviteDetails) {
  const emailInputPreset = document.querySelector(
    '#c1-gigya-container .inputEmail input'
  );
  emailInputPreset.value = inviteDetails.email;
  emailInputPreset.setAttribute('disabled', 'disabled');
}

function disableFieldsAndLinks() {
  const registerLink = document.querySelector('#c1-gigya-container .nemo-link');
  registerLink.classList.add('d-none');
}

function setInvitationTooltipVisibility(visible) {
  const invitationTooltip = document.querySelector(
    '#c1-gigya-container .invite-tooltip'
  );
  invitationTooltip.classList.toggle('d-none', !visible);
}
</script>
